function handleDisplayBSSection () {
    const html = document.querySelector('html');
    html.style.scrollBehavior = 'smooth';

    const btnArr = Array.from(document.querySelectorAll('.bstransform-dropdown-header__close-btn'));
    const sectionBSArr = Array.from(document.querySelectorAll('.section-bsratnsform_drop-field'));

    if (!btnArr.length & !sectionBSArr.length) return;

    btnArr.forEach((btn, index) => {
        btn.addEventListener('click', (event) => {
            event.preventDefault();

            let sectionBS = sectionBSArr[index];
            btn.classList.toggle('active');
            sectionBS.classList.toggle('disp');

            setTimeout(() => {
                animBSSectionBlock(sectionBS);
            }, 200)
        })
    })
}

function handlerBSPageScrollOnForm () {
    const btn = document.querySelector('.section-header__button');
    const block = document.querySelector('#formblock');

    if (!btn && !block) return;

    btn.addEventListener('click', () => {
        block.scrollIntoView({
            behavior: 'smooth',
        })
    })
}

function handlerBSServiceScrollAndChecked () {
    const btnArr = Array.from(document.querySelectorAll('.bsagents-button_scroll'));
    const block = document.querySelector('#formblock');

    if (!btnArr.length || !block) return;

    const options = Array.from(block.querySelector('.bsagents-feedback__select').children);

    btnArr.forEach(btn => {
        const option = options.find(item => {
            return btn.getAttribute('data-service-id') === item.value;
        })

        btn.addEventListener('click', () => {
            options.forEach(item => item.selected = false);
            option.selected = true;

            block.scrollIntoView({
                behavior: 'smooth',
            })
        })
    })
}

function handlerBSScrollByService () {
    const blocksByScroll = Array.from(document.querySelectorAll('[data-service-id'));
    const blocksScrollInit = Array.from(document.querySelectorAll('.section-bsagents__list-item'));

    if (!blocksByScroll.length || !blocksByScroll.length) return;

    blocksScrollInit.forEach((item, index) => {
        item.addEventListener('click', () => {
            blocksByScroll[index].scrollIntoView({
                behavior: 'smooth',
            })
        })
    })    

}

function animBSSectionBlock (section) {
    const titleArr = Array.from(section.querySelectorAll('.column-title'));
    const textArr = Array.from(section.querySelectorAll('.column-text'));

    startAnimBSSection(titleArr);
    startAnimBSSection(textArr);
}

function startAnimBSSection (item) {
    item.forEach((item, index) => {
        let time = (index + 1) * 200;

        setTimeout(() => {
            item.classList.toggle('active')
        }, time)
    })
}


try {
    handlerBSPageScrollOnForm();
    handlerBSScrollByService();
    handlerBSServiceScrollAndChecked();
    handleDisplayBSSection();
} catch(err) {
    console.log('Err:', err);
}
