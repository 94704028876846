function renderPointBySrcoll () {
    const blockBySroll = Array.from(document.querySelectorAll('.nav-section_data'));
    const navBlock = document.querySelector('.custom-nav');

    if (!blockBySroll.length || !navBlock) return;

    const browser = navigator.userAgent;
    let isSafari = false;
    const indexSafari = browser.indexOf('Safari');
    // console.log(typeof browser,indexSafari - 7, Number(browser.slice(indexSafari - 10, indexSafari - 7)) === 110)
    // console.log(browser)
    if (browser.includes('Safari') && !(browser.includes('Chrome'))) {
        
        if (Number(browser.slice((indexSafari - 7), indexSafari - 5)) < 15) isSafari = true;
    }

    const hanlderSelectBlock = (index) => {
        Array.from(navBlock.children).forEach(item => {
            item.classList.remove('current');
        })

        navBlock.children[index].classList.add('current');
    }

    blockBySroll.forEach((elem, index) => {
        const span = document.createElement('span');
        span.classList.add('custom-nav__item'); 

        if (elem.hasAttribute('data-nav-id')) {
            const link = document.createElement('a');
            link.classList.add('custom-nav__bullet');
            link.href = `#${elem.getAttribute('data-nav-id')}`;
    
            link.addEventListener('click', (ev) => {
                ev.preventDefault();
    
                hanlderSelectBlock(index);

                isSafari ? handlerSroll(blockBySroll[index]) 
                    :!index ? window.scrollTo({ top: 0, behavior: 'smooth'}) 
                        : blockBySroll[index].scrollIntoView({ block: 'start', behavior: 'smooth' })
            })
            span.append(link);
        }


        if (elem.hasAttribute('data-nav-title')) {
            const title = document.createElement('span');
            title.classList.add('custom-nav__title');
            title.textContent = `${elem.getAttribute('data-nav-title')}`;
            span.append(title);
        }srolls
        
        navBlock.appendChild(span);
    })

    const windHeight = window.innerHeight;

    window.addEventListener('scroll', () => {
        blockBySroll.forEach((initRow, index) => {
            let initRowPos = initRow.getBoundingClientRect();
            if (initRowPos.top < windHeight / 2) hanlderSelectBlock(index);
        })
    })
}

function handlerSroll (itemBy) {
    let scrollPos = window.pageYOffset;
    let curr = scrollPos;
    let step = itemBy.getBoundingClientRect().top / 100;

    const timer = setInterval(() => {
        curr += step;
        document.body.scrollTop = curr;
        document.documentElement.scrollTop = curr;

        if (itemBy.getBoundingClientRect().top <= 0) {
            clearInterval(timer)
        };
    }, 5)
}

renderPointBySrcoll();