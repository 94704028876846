import {width, delay} from './util'
import './app/sliders'
import './app/other'
import './app/form'
import './app/timer'
import './app/tab'
import './app/point-scroll'
import './app/integrator'
import './app/bsagents'
import './app/init'

//import Scrollbar from 'smooth-scrollbar';

//Scrollbar.init(document.querySelector('#scrollbar'), options);

import SimpleBar from 'simplebar';

if ($(".tab-scrollbar-1").length) {
	new SimpleBar(document.querySelector('.tab-scrollbar-1'));
}

if ($(".tab-scrollbar-2").length) {
	new SimpleBar(document.querySelector('.tab-scrollbar-2'));
}

if ($(".tab-scrollbar-3").length) {
	new SimpleBar(document.querySelector('.tab-scrollbar-3'));
}

if ($(".tab-scrollbar-4").length) {
	new SimpleBar(document.querySelector('.tab-scrollbar-4'));
}

if ($(".tab-scrollbar-10").length) {
	new SimpleBar(document.querySelector('.tab-scrollbar-10'));
}

if ($(".tab-scrollbar-9").length) {
	new SimpleBar(document.querySelector('.tab-scrollbar-9'));
}

if ($(".tab-scrollbar-8").length) {
	new SimpleBar(document.querySelector('.tab-scrollbar-8'));
}

if ($(".tab-scrollbar-7").length) {
	new SimpleBar(document.querySelector('.tab-scrollbar-7'));
}

if ($(".tab-scrollbar-6").length) {
	new SimpleBar(document.querySelector('.tab-scrollbar-6'));
}

if ($(".tab-scrollbar-5").length) {
	new SimpleBar(document.querySelector('.tab-scrollbar-5'));
}
//import 'simplebar/dist/simplebar.css';

//new SimpleBar(document.getElementById('scrollbar'));

//const accordions = document.querySelectorAll('.accordion_agancy');

/*accordions.forEach(el => {
	el.addEventListener('click', (e) => {
		const self = e.currentTarget;
		const control = self.querySelector('.accordion__header');
		const content = self.querySelector('.accordion__body');

		self.classList.toggle('accordion--active');

		// если открыт аккордеон
		if (self.classList.contains('accordion--active')) {
			control.setAttribute('aria-expanded', true);
			content.setAttribute('aria-hidden', false);
			content.style.maxHeight = content.scrollHeight + 'px';
		} else {
			control.setAttribute('aria-expanded', false);
			content.setAttribute('aria-hidden', true);
			content.style.maxHeight = null;
		}
	});
}); */

const handlerEvAccordionHeaderOnDigitalService = () => {
	const acc = Array.from(document.querySelectorAll('.accordion__header'));

	if (!acc.length) return;

	acc.forEach(item => {
		item.addEventListener("click", function() {
			item.classList.toggle("active");	
			/* Toggle between hiding and showing the active panel */
			const panel = item.nextElementSibling;
			panel.style.display === "block" ? panel.style.display = "none" : panel.style.display = "block";
	  });
	})
}

handlerEvAccordionHeaderOnDigitalService();
