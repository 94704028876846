function integratorPageMain() {
    const serviceBlock = document.querySelector('.integrator-service-block');

    if (!serviceBlock) return;

    const typeCircleArr = Array.from(serviceBlock.querySelectorAll('.type-circle'));
    const typeColumn = Array.from(serviceBlock.querySelectorAll('.service-types-column'));
    const lineFilling = serviceBlock.querySelector('.line-filling');

    const serviceArrowsBlock = document.querySelector('.integrator-section-arrows__services');

    if (!serviceArrowsBlock) return;

    const serviceArrows = Array.from(serviceArrowsBlock.querySelectorAll('.section-arrows'));
    const projectArrowsBlock = document.querySelector('.integrator-section-arrows__projects');

    const projectArrows = Array.from(projectArrowsBlock.querySelectorAll('.section-arrows'));

    const integratorSectionBtn = document.querySelector('.integrator-section-header__button');
    const integratorForm = document.querySelector('.integrator-request');

    const serviceContentBlocks = Array.from(document.querySelectorAll('.integrator-section-services'));

    const projectsBlock = document.querySelector('.projects-list');

    const projects = Array.from(document.querySelectorAll('.projects-list__item'));

    const fillLine = (index, lenght) => {
        if (!lineFilling) return;

        const procentFill = (index + 1) === lenght ? 100 : (index + 1) * 20;
        lineFilling.style.width = `${procentFill}%`;
    }

    const activeServiceType = (index) => {
        
        if (!serviceContentBlocks.length) return;
        const activeServiceBlock = serviceContentBlocks[index];
        const serviceBlockHeader = activeServiceBlock.querySelector('.service-header');
        const serviceListItem = Array.from(activeServiceBlock.querySelectorAll('.service-list-item'));

        typeColumn.forEach(item => item.classList.remove('active'));
        typeColumn[index].classList.add('active');

        fillLine(index, typeColumn.length);

        serviceContentBlocks.forEach(item => item.classList.remove('active'));
        activeServiceBlock.classList.add('active');
        serviceBlockHeader.classList.add('show-block');
        serviceListItem.forEach((item, index) => {
            item.style.animationDelay = `${(index + 1) * 0.2}s`;
        });
    }

    const evByServiceTypes = () => {
        if (!serviceBlock) return;
        
        typeCircleArr.forEach((item, index) => {
            item.addEventListener('mouseover', () => {
                typeColumn[index].classList.add('hover-ev');
            })

            item.addEventListener('mouseleave', () => {
                typeColumn[index].classList.remove('hover-ev');
            })

            item.addEventListener('click', () => {
                activeServiceType(index);
            })
        })
    }

    const handlerServiceArrowEv = (index) => {
        serviceArrows.forEach(elem => elem.classList.remove('deactive'));
        serviceArrows[index].classList.add('deactive');
    }
    const transTypesBlock = (bolValue) => {
        serviceBlock.style.transform =  `translateX(${ !!bolValue ? '-50%' : '0%' })`;
    }

    const evByServiceArrows = () => {
        if (!serviceArrows.length) return;

        serviceArrows.forEach((item, index) => {
            item.addEventListener('click', () => {
                transTypesBlock(index);
                handlerServiceArrowEv(index);
            })
        })
    }

    const evByServiceBlockTouch = () => {
        evByServiceArrows();

        let touchPosStart = 0;
        const windWidth = window.innerWidth;

        serviceBlock.addEventListener('touchstart', (ev) => {
            touchPosStart = ev.touches[0].clientX;
        })

        let timerService;

        serviceBlock.addEventListener('touchmove', (ev) => {
            // ev.preventDefault();
            clearTimeout(timerService);
            
            if (Math.abs(ev.touches[0].clientX - touchPosStart) < windWidth / 5) return;

            timerService = setTimeout(() => {
                const bolValue = ev.touches[0].clientX < touchPosStart;
                transTypesBlock(bolValue);
                bolValue ? handlerServiceArrowEv(1) : handlerServiceArrowEv(0);
            }, 50);
        })
    }

    const transProjectSlideNext = (index) => {
        const indexActiveProj = projects.findIndex(elem => elem.classList.contains('active'));

        if (indexActiveProj === projects.length - 1) return;

        projects.forEach(proj => {
            proj.classList.remove('active');
        })
        projects[indexActiveProj + 1].classList.add('active');

        if (!indexActiveProj && indexActiveProj + 1 !== projects.length - 1) {
            projectArrows.forEach(elem => elem.classList.remove('deactive'));
        }

        if (indexActiveProj + 1 === projects.length - 1) {
            projectArrows.forEach(elem => elem.classList.remove('deactive'));
            projectArrows[index].classList.add('deactive');
        }

        projectsBlock.style.transform = `translateX(-${100  * (indexActiveProj + 1) }%)`;
    }

    const transProjectSlidePrev = (index) => {
        const indexActiveProj = projects.findIndex(elem => elem.classList.contains('active'));

        if (!indexActiveProj) return;

        projects.forEach(proj => {
            proj.classList.remove('active');
        })

        projects[indexActiveProj - 1].classList.add('active');
        projectArrows.forEach(elem => elem.classList.remove('deactive'));

        if (!(indexActiveProj - 1)) projectArrows[index].classList.add('deactive');

        projectsBlock.style.transform = `translateX(-${100  * (indexActiveProj - 1) }%)`;
    }

    const evTouchMoveProjects = (position, posStart) => {
        position > posStart ? transProjectSlidePrev(0) : transProjectSlideNext(1);
    }

    const evByProjects = () => {
        if (!projectArrows.length || !projects.length) return;

        projectArrows.forEach((item, index) => {
            item.addEventListener('click', () => {
                index ? transProjectSlideNext(index) : transProjectSlidePrev(index);
            })
        })

        const windWidth = window.innerWidth;
        let touchPosStart = 0;

        projectsBlock.addEventListener('touchstart', (ev) => {
            touchPosStart = ev.touches[0].clientX;
        })

        let timerProjects;
        projectsBlock.addEventListener('touchmove', (ev) => {
            // ev.preventDefault();
            clearTimeout(timerProjects);

            if (Math.abs(ev.touches[0].clientX - touchPosStart) < windWidth / 5) return;

            timerProjects = setTimeout(() => {
                evTouchMoveProjects(ev.touches[0].clientX, touchPosStart);
            }, 50);
        })
    }

    const evByIntegratorBtn = () => {
        if (!integratorSectionBtn) return;

        integratorSectionBtn.addEventListener('click', () => {
            integratorForm.scrollIntoView({
                block: 'center',
                behavior: 'smooth'
            });
        })
    }

    evByIntegratorBtn();
    evByServiceBlockTouch();
    evByServiceTypes();
    evByProjects();
}

integratorPageMain();

