$(document).ready(() => {
	$('.projects_mobile .projects__item').each(function (evt) {
		$(this).click(function (evt) {
			$(this).toggleClass('projects__item_active')
			$(this).siblings().removeClass('projects__item_active')
		});
	});
	

	$('.header__toggler_search').click(function (evt) {
		$('.modal-overlay').show();
		$('.modal-backdrop').show();
		$('body').addClass('overflow-hidden');
	});

	$('.modal-backdrop').click(function (evt) {
		$(this).hide();
		$('.modal-overlay').hide();
		$('body').removeClass('overflow-hidden');
	});

	$('.megamenu__header').click(function (evt) {
		$('.header').toggleClass('header_fixed');
	});

	$('.megamenu__title_btn').click(function (evt) {
		$(this).toggleClass('active');
	});

	$('.gmnoprint').css('right', '100px!important');
	$('.gm-bundled-control').css('right', '100px!important');

	$(document).on('click','.tab__item',function (evt) {
		$('.tab__materials').removeClass('active__materials').addClass('hide__materials');
		$('.tab__materials_'+$(this).attr('data-item')).addClass('active__materials').removeClass('hide__materials');
	})
	
	$('.order__btn').each(function (evt) {
		$(this).click(function (evt) {
			$('.swiper-slide-active').toggleClass('swiper-transforms')
		});
	});


	const hiddenFixedLeftBlock = () => {
		const fixedHiddenItem = document.querySelector('.fixed__right');
		const clientHeight = document.documentElement.clientHeight;

		window.addEventListener('scroll', () => {
			let scrollTop = document.documentElement.scrollTop;
			let scrollHeight = document.documentElement.scrollHeight;

			(scrollHeight - scrollTop - clientHeight) < 250 ? 
				fixedHiddenItem.classList.add('fixed__hidden')
				: fixedHiddenItem.classList.remove('fixed__hidden');
		});
	};

	hiddenFixedLeftBlock();

	$('.fixed__nav-item_scroll').each(function (evt) {
		$('.fixed__nav-bullet', this).click(function (evt) {
			evt.preventDefault();
			var bullet__index = $(this).parent().index();

			$(this).addClass('active');
			$(this).parent().siblings().find('a').removeClass('active');
			
			if (bullet__index == 0) {
				$('html,body').animate({
					scrollTop: $('body').offset().top
				}, 200);
			} else {
				$('html,body').animate({
					scrollTop: $('body').innerHeight() / (bullet__index + 1)
				}, 200);
			}
			return false;
		});
	});

	const count = $('.fixed__nav-item_scroll').length;
	if (count) {
		const coords = [];
		for (let i = 1; i < count; i++) {
			coords.push(parseInt($('body').innerHeight() / (i + 1)));
		}
		coords.push($('body').offset().top);

		$(window).on('scroll', function () {
			const scrollTop = $(document).scrollTop();
			for (let i = 0; i < coords.length; i++) {
				if (scrollTop >= coords[i]) {
					let index = coords.length - i - 1;
					if (index == 1) index = 2;
					else if (index == 2) index = 1;
					$('.fixed__nav-item_scroll').eq(index).siblings().find('.fixed__nav-bullet').removeClass('active');
					$('.fixed__nav-item_scroll').eq(index).find('.fixed__nav-bullet').addClass('active');
					break;
				}
			}
		});
	}
	 
	window.addEventListener('scroll', () => {
		window.pageYOffset > 0.2 * document.body.scrollHeight ?
			document.querySelector('.page-btn_up').classList.add('active')
			:document.querySelector('.page-btn_up').classList.remove('active');
	});

	document.querySelector('.page-btn_up').addEventListener('click', (e) => {
		document.querySelector('#panel').scrollIntoView({
			behavior: 'smooth'
		});
	});
	
	$('.digital__btn').on('click', function() {
		$('html,body').animate({scrollTop:$('.order').offset().top+"px"},{duration:1E3});
	});

	$('.btn_agancy').on('click', function() {
		$('html,body').animate({scrollTop:$('.order').offset().top+"px"},{duration:1E3});
	});

	$('.megamenu__header').click(function (evt) {
        $('.megamenu__header').toggleClass('megamenu__active');
	});
});





