//$(function() {
  //  setTimeout(function() {
    //  $('#footer').show(1000);
   // }, 50000);
//});


$(document).ready(function() {
    var timer;
    $('.section_map').hover(function() {
      clearTimeout(timer);
      var obj = $('.section__footer');

      timer = setTimeout(function() {
        //obj.removeClass('footer__show');
        obj.addClass('footer__show');
      }, 500);
  
    });
    
    $('.fixed__nav-item').on('click', function() {
        $('.section__footer').removeClass('footer__show');
	});
  
});
//mouseenter