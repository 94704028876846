//import Swiper, { EffectCards, Navigation, Pagination } from 'swiper';
import Swiper from 'swiper/swiper-bundle';

var swiper__projects_desktop = new Swiper('.projects__swiper_desktop', {
	slidesPerView: 1,
    slidesPerGroup: 1,
	slideNextClass: 'projects__item_next',
	pagination: {
		el: '.swiper-pagination',
		type: 'fraction',
	},
	navigation: {
		nextEl: '.projects__nav_next',
		prevEl: '.projects__nav_prev',
	},
	breakpoints: {
		768: {
			slidesPerView: 3,
			slidesPerGroup: 3,
		},
		/*
		1024: {
			slidesPerView: 4,
		},
		*/
		1440: {
			slidesPerView: 5,
			slidesPerGroup: 5,
		}
	}
});

var swiper__projects_mobile = new Swiper('.projects__swiper_mobile', {
    slidesPerView: 'auto',
	spaceBetween: 0,
});

var swiper__thumbs = new Swiper('.content__slides-thumbs', {
	spaceBetween: 0,
	watchSlidesVisibility: true,
	watchSlidesProgress: true,
	navigation: {
		nextEl: '.content__slides-next',
		prevEl: '.content__slides-prev',
	},
	breakpoints: {
		320: {
			slidesPerView: 'auto',
		},
		768: {
			slidesPerView: 'auto',
		},
		1366: {
			slidesPerView: 'auto',
		}
	}
});

var swiper__top = new Swiper('.content__slides-top', {
	// spaceBetween: 0,
	pagination: {
		el: '.content__slides-scrollbar',
		type: 'progressbar',
		clickable: true,
	},
	thumbs: {
		swiper: swiper__thumbs
	},
	navigation: {
		nextEl: '.content__slides-next',
		prevEl: '.content__slides-prev',
	},
	clickable: true,
});
var about_slides = new Swiper('.about_slides', {
    slidesPerView: 'auto',
	spaceBetween: 0,
	pagination: {
		el: '.swiper-pagination',
		clickable: true,
	},
});

var offers_slides = new Swiper('.offers_slides', {
    slidesPerView: 'auto',
	spaceBetween: 0,
});

var offers_slides = new Swiper('.search__filter', {
    slidesPerView: 'auto',
	spaceBetween: 0,
});

 /* news*/
//  if ($('.newspromo__sidebar').length > 0) {
//    let swiperNews = new Swiper(".newspromo__sidebar", {
//      direction: "vertical",
//      slidesPerView: "auto",
//      spaceBetween: 30,
//      mousewheel: true,
//       navigation: {
//        prevEl: '.news__prev',
//        nextEl: '.news__next',

//       },

//   });
// }

// var swiper = new Swiper(".content__mobile-swiper", {
// 	slidesPerView: 1,
// 	slidesPerGroup: 1,
// 	loop: true,
// 	loopFillGroupWithBlank: true,
// 	pagination: {
// 	  el: ".swiper-pagination",
// 	  clickable: true,
// 	},
//   });

//swiper slide 

// lAUNCH PAGE
const swiperone = new Swiper('.case__swiper', {
	slidesPerView: 1,
	slidesPerGroup: 1,
	spaceBetween: 10,
	navigation: {
		nextEl: '.case-button-next',
		prevEl: '.case-button-prev',
	},
	pagination: {
		el: ".swiper-pagination",
		clickable: true,
		renderBullet: function (index, className) {
		  return '<span class="' + className + '">' + '0' + (index + 1 ) + "</span>";
		},
	},
});

//swiper scrollbar
var swiperoneMobile = new Swiper('.case__scrollbar', {
	direction: "horizontal",
	slidesPerView: 'auto',
	freeMode: true,
	cssMode: true,
	scrollbar: {
		el: '.swiper-scrollbar'
	},
	mousewheel: true,
});


//DIVISION PAGE

if(document.querySelector('.createSwiper_first')) {
	const swiperAboutNav = new Swiper('.tab__pagination ', {
		slidesPerView: 'auto',
		direction: 'vertical',
	});

	const swiperAbout = new Swiper('.createSwiper_first', {
		effect: 'cards',
		grabCursor: true,
		pagination: {
			el: ".swiper-pagination",
			clickable: true,
		},
		thumbs: {
			swiper: swiperAboutNav
		},
		pagination: {
			el: ".swiper-pagination",
			clickable: true,
		},
	});

	swiperAbout.on('slideChange', function (e) {
		//console.log('.tab__item_'+(e.activeIndex+1));
		$('.tab__item_'+(e.activeIndex+1)).click();
		//$('.tab__materials_'+e.activeIndex).addClass('active__materials'); 
		 
	  });
}

// Digital slides groups 

var swiperDigitalFirst = new Swiper('.swiperDigitalFirst', {
	//modules: [ Navigation, Pagination ],
	pagination: {
		el: '.case__pagination',
		type: 'custom',
		renderCustom: function(swiper, current, total) {
			return `<span class="swiper-pagination-current">  ${('' + current).slice(-2)}  </span>
					<span class="swiper-pagination-total">/ ${('' + total).slice(-2)} </span>`
		}
	},
	
	breakpoints: {
        480: {
			navigation: {
				nextEl: '.case__next',
				prevEl: '.case__prev',
			},
        },
        768: {
			navigation: {
				nextEl: '.case__next',
				prevEl: '.case__prev',
			},
        },
        980: {
			navigation: {
				nextEl: '.case__next',
				prevEl: '.case__prev',
			},
        },
		1280: {
			navigation: {
				nextEl: '.case__next',
			//	prevEl: '.case__prev',
			},
        }
    },
	effect: "fade",
	fadeEffect: {
		crossFade: true
	},
	autoplay: {
    	delay: 10000,
		disableOnInteraction: false
  	},
  	slidesPerView: 1,
	loop: true,
	watchSlidesProgress: true,
	watchOverflow: true,
	//watchSlidesProgress: true,
	watchSlidesVisibility: true,
	roundLengths: true,
});

var swiperDigitalSecond = new Swiper('.swiperDigitalSecond', {
	pagination: {
		el: '.case__pagination',
		//clickable: true
		type: 'custom',
        renderCustom: function (swiper, current, total) {
          return `<span class="swiper-pagination-current">  ${('' + current).slice(-2)}  </span>
                  <span class="swiper-pagination-total">/ ${('' + total).slice(-2)} </span> `
        }
	},
	
	breakpoints: {
        480: {
			navigation: {
				nextEl: '.case__next',
				prevEl: '.case__prev',
			},
        },
        768: {
			navigation: {
				nextEl: '.case__next',
				prevEl: '.case__prev',
			},
        },
        980: {
			navigation: {
				nextEl: '.case__next',
				prevEl: '.case__prev',
			},
        },
		1280: {
			navigation: {
				nextEl: '.case__next',
			//	prevEl: '.case__prev',
			},
        }
    },
	effect: "fade",
	fadeEffect: {
		crossFade: true
	},
	autoplay: {
    	delay: 10000,
		disableOnInteraction: false
  },
  	slidesPerView: 1,
	loop: true,
	watchSlidesProgress: true
});

var swiperDigitalThird = new Swiper('.swiperDigitalThird', {
	pagination: {
		el: '.case__pagination',
		//clickable: true
		type: 'custom',
        renderCustom: function (swiper, current, total) {
          return `<span class="swiper-pagination-current">  ${('' + current).slice(-2)}  </span>
                  <span class="swiper-pagination-total">/ ${('' + total).slice(-2)} </span> `
        }
	},
	
	breakpoints: {
        480: {
			navigation: {
				nextEl: '.case__next',
				prevEl: '.case__prev',
			},
        },
        768: {
			navigation: {
				nextEl: '.case__next',
				prevEl: '.case__prev',
			},
        },
        980: {
			navigation: {
				nextEl: '.case__next',
				prevEl: '.case__prev',
			},
        },
		1280: {
			navigation: {
				nextEl: '.case__next',
			//	prevEl: '.case__prev',
			},
        }
    },
	effect: "fade",
	fadeEffect: {
		crossFade: true
	},
	autoplay: {
    	delay: 10000,
		disableOnInteraction: false
  },
  	slidesPerView: 1,
	loop: true,
	watchSlidesProgress: true
});


var swiperDigitalFourth = new Swiper('.swiperDigitalFourth', {
	pagination: {
		el: '.case__pagination',
		//clickable: true
		type: 'custom',
        renderCustom: function (swiper, current, total) {
          return `<span class="swiper-pagination-current">  ${('' + current).slice(-2)}  </span>
                  <span class="swiper-pagination-total">/ ${('' + total).slice(-2)} </span> `
        }
	},
	breakpoints: {
        480: {
			navigation: {
				nextEl: '.case__next',
				prevEl: '.case__prev',
			},
        },
        768: {
			navigation: {
				nextEl: '.case__next',
				prevEl: '.case__prev',
			},
        },
        980: {
			navigation: {
				nextEl: '.case__next',
				prevEl: '.case__prev',
			},
        },
		1280: {
			navigation: {
				nextEl: '.case__next',
			//	prevEl: '.case__prev',
			},
        }
    },
	effect: "fade",
	fadeEffect: {
		crossFade: true
	},
	autoplay: {
    	delay: 10000,
		disableOnInteraction: false
  	},
  	slidesPerView: 1,
	loop: true,
	watchSlidesProgress: true,
});


const dotsContent = new Swiper('.order__dots', {
	slidesPerView: 'auto',
	direction: 'horizontal',
	clickable: false,
	dots: true,
}); 

var stepsSlide = new Swiper('.stepsSlide', {
	pagination: {
		el: '.order__pagination',
		clickable: false,
		type: 'custom',
        renderCustom: function (swiper, current, total) {
         return `<span class="swiper-pagination-current">  ${('' + current).slice(-2)}  </span>
               <span class="swiper-pagination-total">/ ${('' + total).slice(-2)} </span> `
        }
	},
	navigation: {
		nextEl: '.order-next',
		prevEl: '.order-prev',
	},
  	slidesPerView: 1,
//	loop: true,
	allowTouchMove: false,
	thumbs: {
		swiper: dotsContent,
		clickable: false,
	},
	watchSlidesProgress: true
});


const initSlidersArr = Array.from(document.querySelectorAll('.init-project__slides'));
initSlidersArr.forEach(initSlidersArrEl => {
	const initSlidersPagination = initSlidersArrEl.querySelector('.swiper-pagination');
	new Swiper(initSlidersArrEl, {
		slidesPerView: 1,
		pagination: {
			el: initSlidersPagination,
			clickable: true,
			renderBullet: (index, className) => {
				return `<span class="init-project__bullet ${className}">${index + 1}</span>`;
			},
		},
	});
});

$(document).ready(function(){
	if ($(".newspromo").length) {
		var swiper__newspromo = new Swiper('.newspromo', {
			autoHeight: true,
			slidesPerView: 1,
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
			},
			navigation: {
				nextEl: '.newspromo__nav-item_next',
				prevEl: '.newspromo__nav-item_prev',
			}
		});

		swiper__newspromo.on('slideChange', function () {
			//console.log(swiper__newspromo.activeIndex);
			$.cookie('NewsSliderPos',swiper__newspromo.activeIndex, { expires: 7, path: '/' });
		});

		if ($.cookie('NewsSliderPos')!= null) {
			swiper__newspromo.slideTo($.cookie('NewsSliderPos'),1); 
		}
	}
});
$(document).ready(function(){
	if ($(".newspromo__box").length) {
		var swiper__newspromo = new Swiper('.newspromo__box', {
			slidesPerView: 1,
			slidesPerGroup: 1,
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
			},
			navigation: {
				nextEl: '.newspromo__nav-item_next',
				prevEl: '.newspromo__nav-item_prev',
			},
			//clickable: true,
		});

		swiper__newspromo.on('slideChange', function () {
			//console.log(swiper__newspromo.activeIndex);
			$.cookie('NewsSliderPos',swiper__newspromo.activeIndex, { expires: 7, path: '/' });
		});

		if ($.cookie('NewsSliderPos')!= null) {
			swiper__newspromo.slideTo($.cookie('NewsSliderPos'),1); 
		}
	}
});