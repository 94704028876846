
function handlerScrollByInitPage(circleData) {
    const isInits = document.querySelector('.bg_section_init');

    if (isInits) {
        const bodyPage = document.querySelector('body');
        bodyPage.style.cssText = `background: url('/local/templates/cdt/img/bg/bg-inits-space-star.png') center/contain rgb(0, 0, 0);`; 
    }

    const initsRowArr = Array.from(document.querySelectorAll('.init-project__bar-progress'));
    const mainSvgBlock = document.querySelector('.cicle-svg');

    const projectProgressSection = document.querySelector('.project-progress-section');

    const windHeight = window.innerHeight;
    let flagByCircleDisp = true;

    window.addEventListener('scroll', () => {
        evByInitBarProgress(initsRowArr, windHeight);

        if (!flagByCircleDisp || !projectProgressSection) return;

        let initCirclePos = projectProgressSection.getBoundingClientRect();
            
        if (initCirclePos.top < windHeight / 2)  {
            flagByCircleDisp = false;
            animSvg(mainSvgBlock, circleData);
        }      
    })

    renderSvg(mainSvgBlock);

    renderPtojectTitleByProgressCircle(circleData);

    window.addEventListener('resize', () => renderPtojectTitleByProgressCircle(circleData));
}

function evByInitBarProgress (arr, windHeight) {
    if (!arr.length && !windHeight) return;

    arr.forEach(initRow => {
        let initRowPos = initRow.getBoundingClientRect();
        if (initRowPos.top < windHeight / 2)  {
            const initRowWidth = initRow.getAttribute('data-width');
            initRow.style.cssText = `transition: 1s; transition-delay: .7s; width: ${initRowWidth}%`;
        }
    })
}

function renderSvg (mainSvg) {
    if (!mainSvg) return;

    const circleArr = Array.from(mainSvg.querySelectorAll('.cicle-with-gradient'));

    circleArr.forEach(item => {
        const rCircle = item.getAttribute('r');
        const dashValue = Math.round(rCircle * 3.14 * 2);

        item.setAttribute('stroke-dasharray', `0,${Math.round(dashValue / 2)} 0,${Math.round(dashValue / 2)}`);
    })
}

function animSvg(mainSvg, circleData) {
    if (!mainSvg) return;

    const circleArr = Array.from(mainSvg.querySelectorAll('.cicle-with-gradient'));

    circleArr.forEach( (item, index) => {

        const rCircle = item.getAttribute('r');
        const dashValue = Math.round(rCircle * 3.14 * 2);
        const valuePorgressInPorcent = dashValue * circleData[index].progress / 100;

        let jsAnim = document.createElementNS('http://www.w3.org/2000/svg','animate');

        jsAnim.setAttribute('attributeName', 'stroke-dasharray');
        jsAnim.setAttribute('dur', '5s');
        jsAnim.setAttribute('fill', 'freeze');
        jsAnim.setAttribute('values', `0,${Math.round(dashValue / 2)} 0,${Math.round(dashValue / 2)};0,0,${valuePorgressInPorcent},${dashValue - valuePorgressInPorcent}`);

        item.appendChild(jsAnim);
        item.setAttribute('stroke', 'url(#linear-gradient)');
    })
}

function renderPtojectTitleByProgressCircle(arr) {
    const circleProgress = document.querySelector('.circle-progress');

    if (!circleProgress) return;

    const valueByBottom = circleProgress.offsetHeight / 10;

    circleProgress.innerHTML = '';

    arr.forEach((circle,index) => {
        const li = document.createElement('li');
        const link = document.createElement('a');

        li.classList.add('title');
        li.style.bottom = `${index * valueByBottom - 5}px`;
        
        link.href = `#${circle.id}`;
        link.textContent = `${circle.title}`;

        li.append(link);
        circleProgress.append(li);
    })
}

const initsPageArrCircle = [
    {
        title: 'SpaceTwin',
        progress: 60,
        id: 'spacetwin',
    },
    {
        title: 'CryptoSat',
        progress: 70,
        id: 'cryptosat',
    },
    {
        title: 'EduXSat',
        progress: 100,
        id: 'eduxsat',
    },
    {
        title: 'Oculus',
        progress: 80,
        id: 'oculus',
    },
];

handlerScrollByInitPage(initsPageArrCircle);