import classie from 'desandro-classie';

$(document).ready(() => {
	[].slice.call( document.querySelectorAll('input')).forEach(function(input) {
		if( input.value.trim() !== '' ) {
			classie.add( input.parentNode, 'focused');
		}

		input.addEventListener('focus',onInputFocus);
		input.addEventListener('blur',onInputBlur);
	});

	function onInputFocus(ev) {
		classie.add(ev.target.parentNode, 'focused');
	}

	function onInputBlur( ev ) {
		if( ev.target.value.trim() === '' ) {
			classie.remove(ev.target.parentNode,'focused');
		}
	};
});